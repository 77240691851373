@import "../utils/colors.scss";
.brand{
    cursor: pointer;
    span{
        text-transform: uppercase;
        font-size: xx-large;
        border:0.1rem solid #f50460;
        color:$blueColor;
        padding:0.6rem 4rem;
        font-weight: bolder;
        border-radius: 0.3rem;
        letter-spacing: 0.2rem;

    }
}
.footer{
    span{
        color: white;
        border-color: white;

    }
}
@media screen and (min-width:320px) and (max-width:480px){
    .brand{
        span{
            font-size:small;
        }
    }
}
