@import "../utils/colors.scss";

.why-container {
  min-height: 50hv;
  .container {
    margin: 0 10%;
    padding: 5rem 0;
    .top {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    .title {
      margin-bottom: 1rem;
    }
    .subTitle {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    p {
      width: 65%;
      text-align: center;
      color: $lightFontColor;
    }
  }
}
.content{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    margin-top: 1rem;
    .reasons{
        height: 100%;
        display: flex;
        align-items: center;
        ul{
            width: 80%;
            list-style-type: none;
            li{
                color:$lightFontColor;
                margin-bottom: 1.5rem;
                padding-left: 45px;
                position: relative;
                &::before{
                    content: "";
                    position: absolute;
                    top:-4px;
                    left: 0;
                    width: 24px;
                    height: 24px;
                    background-image: url("../../assets/check-circle.svg");
                    background-repeat: no-repeat;
                }
            }
        }

    }
}
.video{
    height: 18rem;
    width: 100%;
    background-image: url("../../assets/mega_FB.png");
    background-repeat: no-repeat;
    display: flex;
    border-radius: 1rem;
    justify-content: center;
    align-items: center;
    padding: 2rem 0;
   
}
@media screen and (min-width:320px) and (max-width:480px){
  .why-container{
    .container{
      .top{
        .subTitle{
          font-size: 1.6rem;
        }
      }
    }
    .content{
      grid-template-columns: 1fr;
      .reasons{
        ul{
          li{
            margin-bottom: 3rem;
            font-size: 1.7rem;
            &::before{
              top:0px
            }
          }
        }
      }
      .video{
       display: none;
      }
    }
  }
}
@media screen and (min-width:481px) and (max-width:768px){
  .why-container{
    .container{
      .top{
        .subTitle{
          font-size: 3rem;
        }
      }
    }
    .content{
      grid-template-columns:2fr;
      .reasons{
        ul{
          li{
            font-size: 3rem;
            margin-bottom: 3rem;
          }
        }
      }
    }
    .video{
      display: none;
   }
  }
}
@media screen and(min-width:1024px){
  .why-container{
    .container{
      .top{
        .subTitle{
          font-size:1.5rem;
        }
      }
    }
    .content{
    
      .reasons{
        ul{
          li{
            font-size: 1.5rem;
            margin-bottom: 3rem;
          }
        }
      }
    }
  }
  }


