@import "../utils/colors.scss";

.testimonials-container {
  background-color: $backgroundOtherColor;
  .container {
    margin: 0 10%;
    padding: 5rem 0;
    .title-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .title {
        margin-bottom: 1rem;
      }
      p {
        color: $lightFontColor;
      }
    }
    .testimonials {
      margin-top: 1.5rem;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 3rem;
    }
  }
}
@media screen and (min-width: 320px) and (max-width: 480px) {
  .testimonials-container {
    .container {
      .title-container {
        p {
          font-size: 1.7rem;
        }
      }
      .testimonials {
        grid-template-columns: 1fr;
      }
    }
  }
}

@media screen and (min-width: 1024px) and (max-width: 1366px) {
  .testimonials-container {
    width: 115.5vw;
    .container {
      .title-container {
        p {
          font-size: 1.5rem;
        }
      }
    }
  }
}
