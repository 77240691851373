@import "../utils/colors.scss";

.about-container {
  background-color: $backgroundOtherColor;
  .container {
    margin: 0 10%;
    padding: 5rem 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 3rem;
    .details {
      p {
        color: $lightFontColor;
        line-height: 1.3rem;
        font-size: large;
        text-align: justify;
        width: 90%;
      }
    }
    .cards {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1.5rem;
    }
  }
}
@media screen and (min-width:320px) and (max-width:480px){
    .about-container{
        .container{
            grid-template-columns: 1fr;
            .details{
                p{
                    font-size: 2.3rem;
                    line-height: 2rem;
                    width: 100%;
                }
            }
            .cards{
                grid-template-columns: 1fr;
                 
            }
        }
    }
}
@media screen and (min-width:481px) and (max-width:768px){
    .about-container{
      
        .container{
            .details{
                p{
                    font-size: 1.8rem;
                    line-height: 1.7rem;
                    width:100%;
                }
            }
        }
    }
}
@media screen and (min-width:769px) and (max-width:1024px){
    .about-container{
        width: 100%;
        .container{
            gap:1rem;
            .details{
                p{
                    font-size: 1rem;
                    line-height: 1.5rem;
                    width: 100%; 
                }
            }
           .cards{
               gap:1rem;
           }
        }
    }
}
@media screen  and (min-width:1024px)and(max-width: 1366px){
    .about-container{
        
            .container{
                .details{
                    p{
                        font-size: 1.3rem;
                        line-height: 1.3rem;
                        width: 100%;  
                    }
                }
            gap: 2rem;
            .cards{
                gap:1rem;
            }
        }
    }
}
